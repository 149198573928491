import HomeList from "@/page/distributor/hotel/components/homeList";
import {
  getHotelNightlyRates,
  queryRoomRatePlan,
  queryRoomPackageList,
  queryRoomList,
} from "@/lib/data-service/hotel";
import { STATUS } from "@/lib/deprecated/utils/status";
import { getSession } from "@/lib/deprecated/utils/caching";

const SUCCESS_CODE = STATUS.success;
export default {
  data() {
    return {
      hotelFormDate: {
        currentPage: 1,
        pageSize: 6,
      }, //酒店日历金额
      dateList: [],
      pickerBeginDateBefore: this.setTime(),
      weekPrice: [], //日历金额
      hotelList: [], //原房间列表
      hotelListNum: 10, //房型显示个数
      allShowList: [],
      dayColor: [], //日期显示active
      // img:require('https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/src/assets/hotel.jpg'),
      img:
        "https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/src/assets/hotel.jpg",
      selectDate: [
        { index: 0, date: this.$moment().format("YYYY-MM-DD") },
        { index: 0, date: this.$moment().format("YYYY-MM-DD") },
        { index: 0, date: this.$moment().format("YYYY-MM-DD") },
      ],
      //    pickerOption:this.setTime(),
      homePackage: {
        currentPage: 1,
        pageSize: 5,
      }, //房型套餐
      calendarNow: [], //日历选择
      hotelInfo: [],
      show: false,
      btnShow: false,
    };
  },

  created() {
    let hotelInfo = getSession("HOTELINFO");
    let formData = getSession("HOTELFORMDATA");
    this.hotelInfo.push(hotelInfo);
    this.hotelFormDate = formData;
    this.dateList[0] = new Date(formData.checkInTime);
    this.dateList[1] = new Date(formData.checkOutTime);
    this.getHome(this.dateList, hotelInfo.hotelId, hotelInfo);
  },
  methods: {
    to_seearch() {
      const { checkInTime, checkOutTime, cityId, cityName, countryType, keyword } = this.hotelFormDate;
      const data = JSON.stringify({ checkInTime, checkOutTime, cityId, cityName, countryType, keyword });
      this.$router.replace({
        name: 'distributor-hotel-front-search',
        query: {
          data,
        },
      })
    },


    settlingTime(val) {
      if (!val) return;
      let day = this.datedifference(val[0], val[1]);
      if (day > 30) {
        this.btnShow = true;
        this.$message({ type: "warning", message: "最多可预定30天" });
      } else {
        this.btnShow = false;
      }
    },
    //日历时间查询
    setTime() {
      let _this = this;
      return {
        disabledDate: (time) => {
          if (_this.dateList) {
            if (_this.dateList[0]) {
              return time.getTime() < Date.now() - 1000 * 3600 * 24;
            }
          } else {
            return time.getTime() < Date.now() - 1000 * 3600 * 24;
          }
        },
      };
    },
    //房型列表
    getHome(date, id, list) {
      let data = {
        checkInDate: this.$moment(date[0]).format("YYYY-MM-DD"),
        checkOutDate: this.$moment(date[1]).format("YYYY-MM-DD"),
        hotelId: id,
      };
      let hotelText = {
        text: "查看更多房型",
        statu: false,
        ico: "el-icon-arrow-down",
      };
      this.show = true;
      queryRoomList(data).then(
        (res) => {
          if (res.code === SUCCESS_CODE) {
            this.show = false;
            list.initHotelList = res.data.roomViewList.slice(
              0,
              this.hotelListNum
            );
            list.hotelList = res.data.roomViewList.slice(0, this.hotelListNum);
            list.hotelListAll = res.data.roomViewList;
            this.allShowList.push(hotelText);
            this.$forceUpdate();
          }
        },
        () => {
          this.show = false;
        }
      );
    },
    search() {
      if (!this.dateList) return;
      this.hotelFormDate.checkInTime = this.dateList[0];
      this.hotelFormDate.checkOutTime = this.dateList[1];
      this.getHome(this.dateList, this.hotelInfo[0].hotelId, this.hotelInfo[0]);
    },
    //显示所有房型
    allShowHotelList(index, val) {
      this.allShowList.statu = !this.allShowList.statu;
      let allShow = this.allShowList[index];

      if (this.allShowList.statu) {
        allShow.text = "收起";
        allShow.ico = "el-icon-arrow-up";
        val.hotelList = val.hotelListAll;
      } else {
        allShow.text = "查看更多房型";
        allShow.ico = "el-icon-arrow-down";
        val.hotelList = val.initHotelList;
      }
      this.$forceUpdate();
    },
    datedifference(sDate1, sDate2) {
      var dateSpan, tempDate, iDays;
      sDate1 = Date.parse(sDate1);
      sDate2 = Date.parse(sDate2);
      dateSpan = sDate2 - sDate1;
      dateSpan = Math.abs(dateSpan);
      iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
      return iDays;
    },
  },
  components: { HomeList },
};
