//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { queryRoomRatePlan } from "@/lib/data-service/hotel";
import { STATUS } from "@/lib/deprecated/utils/status";
import { setSession } from "@/lib/deprecated/utils/caching";

const SUCCESS_CODE = STATUS.success;

export default {
  data() {
    return {
      hideListShow: false,
      homePriceList: [],
      dateNow: this.getCalendarNow
    };
  },
  props: {
    index: {
      type: Number,
      default: 1
    },
    list: {
      type: Object,
      default: {}
    },
    hotelFormDate: {
      type: Object,
      default: {}
    },
    queryList: {
      type: Object,
      default: {}
    },
    hotelId: {
      type: Number,
      default: null
    },
    calendarNow: {
      type: String,
      default: ""
    }
  },
  watch: {
    list(val) {
      this.hideListShow = false;
    }
  },
  methods: {
    reserve(val) {
      let data = {
        hotel: this.list,
        formData: this.hotelFormDate,
        hotelList: this.queryList,
        roomPackage: val
      };
      // console.log('123',data)
      let checkInTime = this.hotelFormDate.checkInTime;
      let checkOutTime = this.hotelFormDate.checkOutTime;
      // let date = this.calendarNow?this.calendarNow:this.$moment().format('YYYY-MM-DD')
      let day = this.datedifference(checkInTime, checkOutTime);
      let time = {
        checkInTime: checkInTime,
        checkOutTime: checkOutTime
      };
      if (!checkInTime || !checkOutTime) {
        return this.$message({
          type: "warning",
          message: "请选择入住、退房日期"
        });
      }
      setSession("HOTELORDERDATA", data);
      setSession("HOTELORDERTIME", time);
      this.$router.push({
        name: "distributor-hotel-front-hotel-reserve",
        query: {
          hotelId: this.list.hotelId,
        },
        // query: { data: JSON.stringify(data), time: JSON.stringify(time) }
      });
    },
    //酒店房型报价
    getRommPrice(val, index) {
      this.hideListShow = !this.hideListShow;
    },
    //计算天数
    datedifference(sDate1, sDate2) {
      var dateSpan, tempDate, iDays;
      sDate1 = Date.parse(sDate1);
      sDate2 = Date.parse(sDate2);
      dateSpan = sDate2 - sDate1;
      dateSpan = Math.abs(dateSpan);
      iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
      return iDays;
    }
  }
};
